<template>
  <div class="info_warp">
    <div class="wrap">
      <el-form :model="form"
               label-width="180rem">
        <el-row>
          <el-col :span="24">
            <el-form-item label="输入旧密码：">
              <el-input placeholder="请输入旧密码"
                        v-model="form.old_password"
                        type="password"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="输入新密码：">
              <el-input placeholder="请输入新密码"
                        v-model="form.password"
                        type="password"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="再输新密码：">
              <el-input placeholder="请再输入新密码"
                        v-model="form.password1"
                        type="password"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div class="btn"
                 @click="save">
              确 认
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>



<script>
import { resetPwd } from '@/api/userInfo.js'
export default {
  data () {
    return {
      form: {
        old_password: '',
        password: '',
        password1: '',
      }
    }
  },
  methods: {


    async save () {
      if (this.form.password != this.form.password1) {
        this.$message.warning('两次输入密码不一致')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      delete form.password1
      await resetPwd(form)
      this.form = {
        old_password: '',
        password: '',
        password1: '',
      }
      this.$message.success('修改成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.info_warp {
  display: flex;
  justify-content: center;
  .wrap {
    width: 572rem;
    .el-form {
      margin-top: 50rem;
      ::v-deep .el-form-item__label {
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
        line-height: 60rem;
      }
      ::v-deep .el-input--medium .el-input__inner {
        height: 60rem;
        line-height: 60rem;
        font-size: 24rem;
        font-weight: 500;
        color: #333333;
      }
    }

    .btn {
      width: 572rem;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      font-size: 24rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 60rem;
      text-align: center;
    }
  }
}
</style>